import React from "react"
import theme from "theme"
import { Theme, Text, Image, Box, Section, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Про Клуб | Гольф-клуб Tróia</title>
        <meta name={"description"} content={"Пориньте в гольф-спадщину"} />
        <meta
          property={"og:title"}
          content={"Про Клуб | Гольф-клуб Tróia"}
        />
        <meta
          property={"og:description"}
          content={"Пориньте в гольф-спадщину"}
        />
        <meta
          property={"og:image"}
          content={"https://amirevox.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://amirevox.com/img/001-icon-golf.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://amirevox.com/img/001-icon-golf.png"}
        />
      </Helmet>
      <Components.Header />
      <Section text-align="center" padding="80px 0" sm-padding="40px 0">
        <Text
          as="h1"
          font="--headline1"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Історія клубу
        </Text>
        <Text as="p" font="--lead" margin="20px 0 0 0">
          O Гольф-клуб Tróia evoluiu de um início humilde para um santuário para
          os entusiastas do golfe. O nosso percurso começou com uma visão: criar
          um local onde o amor pelo golfe transcende o próprio jogo. Ao longo
          dos anos, o Tróia transformou-se num paraíso onde as tradições são
          honradas, as competências são aperfeiçoadas e o espírito do golfe é
          celebrado todos os dias. A essência do nosso clube reside na ligação
          intemporal entre a natureza e o desporto, uma relação que acarinhamos
          e cultivamos.
        </Text>
        <Box
          display="flex"
          margin="40px 0 20px 0"
          justify-content="space-around"
          sm-flex-direction="column"
        >
          <Box padding="10px">
            <Image
              src="https://amirevox.com/img/9.jpg"
              width="320px"
              max-width="100%"
            />
          </Box>
          <Box padding="10px">
            <Image
              src="https://amirevox.com/img/10.jpg"
              width="320px"
              max-width="100%"
            />
          </Box>
          <Box padding="10px">
            <Image
              src="https://amirevox.com/img/11.jpg"
              width="320px"
              max-width="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="48px 0 48px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="About-14"
        background="--color-lightD1"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="40%"
          justify-content="flex-start"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          margin="0px 0px 0px 0px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://amirevox.com/img/12.jpg"
            object-fit="cover"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            sm-min-height="100vw"
            height="100%"
          />
        </Box>
        <Box
          display="flex"
          width="60%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          padding="48px 64px 48px 64px"
          justify-content="center"
          background="white"
          md-padding="36px 40px 48px 40px"
          sm-padding="36px 24px 48px 24px"
        >
          <Text
            margin="0px 0px 16px 0px"
            color="--dark"
            font="--headline3"
            lg-text-align="center"
            sm-text-align="left"
          >
            Серце Tróia - Наші поля
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD2"
            font="--base"
            lg-text-align="left"
            sm-text-align="left"
          >
            No centro do Tróia estão os nossos campos meticulosamente
            concebidos, cada um deles criado para desafiar e inspirar. Desde a
            beleza serena do Sunrise Course até ao exigente layout do Sunset
            Course, os nossos greens destinam-se a golfistas de todos os níveis.
            O nosso compromisso com a excelência é visível nos fairways bem
            cuidados, nos perigos estratégicos e nas vistas deslumbrantes que
            fazem de cada partida uma experiência inesquecível.
          </Text>
          <Text
            margin="0px 0px 16px 0px"
            color="--dark"
            font="--headline3"
            lg-text-align="center"
            sm-text-align="left"
          >
            Особливості Troia
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD2"
            font="--base"
            lg-text-align="left"
            lg-margin="0px 0px 36px 0px"
            sm-text-align="left"
          >
            Campos históricos de 18 buracos: Cada campo conta uma parte da nossa
            história, com projectos que respeitam a paisagem natural.
            <br />
            <br />
            Centro de aprendizagem inovador: Equipado com tecnologia de ponta, o
            nosso centro é o local ideal para os golfistas aperfeiçoarem as suas
            capacidades.
            <br />
            <br />
            Eventos de assinatura: Desde torneios de prestígio a encontros
            casuais, o nosso calendário de eventos foi concebido para envolver e
            encantar.
            <br />
            <br />
            Práticas sustentáveis: Dedicamo-nos a preservar o nosso ambiente,
            empregando práticas amigas do ambiente na manutenção e funcionamento
            do campo.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          justify-content="center"
          lg-order="1"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--dark"
            font="--headline2"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Приєднуйтесь до Нас
          </Text>
          <Text
            margin="0px 0px 40px 0px"
            color="--greyD3"
            font="--lead"
            lg-text-align="center"
          >
            O Гольф-клуб Tróia não é apenas um lugar para jogar, é um lugar para
            pertencer. Aqui, cada tacada, cada putt e cada volta enriquecem a
            sua história. Convidamo-lo a fazer parte deste legado, onde o seu
            amor pelo golfe pode florescer no meio de pessoas que pensam da
            mesma forma. Crie a sua viagem, crie memórias inesquecíveis e deixe
            a sua paixão pelo jogo crescer numa comunidade que valoriza e
            celebra todos os aspectos do golfe.
          </Text>
          <Link
            href="/contacts"
            padding="12px 24px 12px 24px"
            color="--light"
            text-decoration-line="initial"
            font="--lead"
            border-radius="8px"
            margin="0px 16px 0px 0px"
            transition="background-color 0.2s ease-in-out 0s"
            sm-margin="0px 0px 16px 0px"
            sm-text-align="center"
            background="--color-primary"
            hover-transition="background-color 0.2s ease-in-out 0s"
            hover-background="--color-primary"
          >
            Контакти
          </Link>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-end"
          lg-width="100%"
          align-items="flex-start"
          lg-margin="0px 0px 32px 0px"
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 32px"
          lg-padding="0px 0px 0px 0px"
          lg-justify-content="center"
        >
          <Image
            src="https://amirevox.com/img/1.jpg"
            object-fit="cover"
            width="100%"
            height="100%"
            border-radius="24px"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            min-height="600px"
            max-width="480px"
            sm-min-height="100vw"
          />
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
